import classNames from "classnames";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import Logo from "../assets/LogoWatermark.svg";
import IconCollaboration from "../assets/icons/ourValues/collaboration.svg";
import IconCuriosity from "../assets/icons/ourValues/curiosity.svg";
import IconEmpathy from "../assets/icons/ourValues/empathy.svg";
import IconSkillMastery from "../assets/icons/ourValues/skillMastery.svg";
import { Layout, SEOTags, WhoWeAreSection } from "../components";
import * as styles from "../styles/whoWeAre.module.scss";

class WhoWeAre extends Component {
  render() {
    const ourValues = [
      {
        icon: <IconSkillMastery />,
        heading: "Skill mastery: ",
        description:
          'Our company name is a play on the word "mahusay", which means competent and reflects our unwavering commitment to delivering high-quality products through mastery of what we do.',
      },
      {
        icon: <IconEmpathy />,
        heading: "Empathy: ",
        description:
          "We take a human-centered approach to design, focusing on the overall user experience and striving to create products that are intuitive, accessible, and enjoyable to use. ",
      },
      {
        icon: <IconCuriosity />,
        heading: "Curiosity and innovation: ",
        description:
          "We are committed to continuous learning and development, and we invest in the latest tools, technologies, and training to stay at the forefront of our industry.",
      },
      {
        icon: <IconCollaboration />,
        heading: "Thought-partnership and global collaboration: ",
        description:
          "We believe in fostering a culture of global collaboration and teamwork in order to bring unique perspectives and ideas to the table. We strive to create an environment where everyone feels included, valued, and empowered to contribute their best work. ",
      },
    ];

    return (
      <Layout layoutClassName="whoWeAre-page">
        <div className={styles.whoWeArepage}>
          <section className={styles.hero}>
            {/* <div className={`${styles.heroAnimation} ${styles.computer}`}>
              <lottie-player
                src="/lottie/Home-hero.json"
                autoplay
                loop
                preserveAspectRatio="xMidYMid slice"
                style={{
                  height: "-webkit-fill-available",
                  width: "-webkit-fill-available",
                }}
              ></lottie-player>
            </div>
            <div className={`${styles.heroAnimation} ${styles.mobile}`}>
              <lottie-player
                src="/lottie/Home-mobile-hero.json"
                autoplay
                loop
                preserveAspectRatio="xMidYMid slice"
                style={{
                  height: "-webkit-fill-available",
                  width: "-webkit-fill-available",
                }}
              ></lottie-player>
            </div> */}
            <Logo className={classNames(styles.watermark, styles.mobile)} />
            <h6 className={styles.header}>WHO WE ARE</h6>
            <Row>
              <h1>
                Bring leading edge global software development teams to deliver
                positive social, economic, and environmental impact to our
                clients
              </h1>
            </Row>
          </section>
          <WhoWeAreSection hideLink />
          <section className={styles.description}>
            <Row>
              <Col lg={6}>
                <h6 className={styles.title}>Our people</h6>
              </Col>
              <Col xs={12} lg={6}>
                <p className={styles.communityHeader}>Strength in Community.</p>
                <div className={styles.community}>
                  <p>
                    We select top talents from around the globe — the top 5% of
                    their class
                  </p>
                  <p>
                    Rigorous training, skills, and leadership mentoring program
                  </p>
                  <p>Relentless learning culture</p>
                  <p>
                    One diverse global community that supports and celebrates
                    differences
                  </p>
                  <p>
                    Commitment to act ethically and make a meaningful change in
                    the world
                  </p>
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col lg={6}>
                <h6 className={styles.title}>Our values</h6>
              </Col>
              <Col xs={12} lg={6} className={styles.values}>
                {ourValues.map((value) => (
                  <div className={styles.value} key={`value-${value.heading}`}>
                    {value.icon}
                    <p>
                      <span className={styles.heading}>{value.heading}</span>
                      {value.description}
                    </p>
                  </div>
                ))}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col lg={6}>
                <h6 className={styles.title}>Our vision</h6>
              </Col>
              <Col xs={12} lg={6}>
                <p>
                  Bring leading edge global software development teams to
                  deliver positive, social, economic, and environmental impact
                  to our clients
                </p>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col lg={6}>
                <h6 className={styles.title}>Our mission</h6>
              </Col>
              <Col xs={12} lg={6}>
                <p>Create value in tech innovation and human connection</p>
              </Col>
            </Row>
          </section>
        </div>
      </Layout>
    );
  }
}

export default WhoWeAre;

export const Head = ({ location }) => (
  <SEOTags title={"Who we are | Mahusai Global"} pathname={location.pathname} />
);
